import config, { hasZendeskProxy } from '@/config'
import { session } from '@/composables/useSession'

interface IntegrationUrlConstructor {
  state: string
  subdomain?: string
  clientId?: string
  salesforceSandbox?: boolean
}

interface BaseIntegration {
  label: string
  value: string
  status?: string
  askMasking?: boolean
  askFieldBlacklist?: boolean
  askGroupList?: boolean
  askIgnoreAttachments?: boolean
  askTagList?: boolean
  askUsername?: boolean
  askDomain?: boolean
  askChannelList?: boolean
  hideAdvancedOptions?: boolean
  hideIgnoreContent?: boolean
}

export interface TokenIntegration extends BaseIntegration {
  askToken?: boolean
  askTokenSecret?: boolean
  askCsatFilter?: boolean
}

export interface OAuthIntegration extends BaseIntegration {
  storageKey: string
  url: (params: IntegrationUrlConstructor) => string
  redirectUri?: string
  askOAuthApp?: boolean
  askMailboxList?: boolean
}

export const integrationZDRedirectKey = 'klausIntegrationZDRedirect.v1'

const integrationOAuthKey = 'klausIntegrationOAuth.v1'

export function isOAuthIntegration(integration: OAuthIntegration | TokenIntegration): integration is OAuthIntegration {
  return 'storageKey' in integration ? true : false
}

export function isTokenIntegration(integration: OAuthIntegration | TokenIntegration): integration is TokenIntegration {
  return 'askToken' in integration || 'askTokenSecret' in integration || 'askCsatFilter' in integration
}

export const callbackUrl = config.integrationCallbackUrl

const identifiers = {
  zendesk: config.zendeskIdentifier,
  intercom: config.intercomIdentifier,
  aircall: config.aircallIdentifier,
  helpscout: config.helpscoutIdentifier,
  livechat: config.livechatIdentifier,
  drift: config.driftIdentifier,
  salesforce: config.salesforceIdentifier,
  front: config.frontIdentifier,
  gorgias: config.gorgiasIdentifier,
}

export const klausOnly = ['MANUAL_IMPORT', 'CORDLESS_MANUAL_IMPORT', 'BROWSER_EXTENSION', 'DEMO']

// Connections where we pull data from the help desk, aka a supported "integration"
export function isIntegrationConnection(sourceType: string) {
  return !klausOnly.includes(sourceType)
}

export const intercomRegions = [
  { label: 'US/Other', value: 'us' },
  { label: 'EU', value: 'eu' },
  { label: 'AUS', value: 'au' },
]

// OAuth-capable integrations should include a URL param for the OAuth callback
// State param in the callback is used for CSRF protection
export const integrationMap = (allIntegrations?: boolean): Record<string, TokenIntegration | OAuthIntegration> => ({
  zendesk: {
    url: ({ subdomain, state }) =>
      [
        `https://${subdomain}.${config.zendeskDomain}`,
        `/oauth/authorizations/new`,
        `?response_type=code&scope=read`,
        `&redirect_uri=${callbackUrl}/zendesk`,
        `&client_id=${identifiers.zendesk}`,
        `&state=${state}`,
      ].join(''),
    label: 'Zendesk',
    value: 'ZENDESK',
    storageKey: integrationOAuthKey,
    askMasking: true,
    askFieldBlacklist: true,
    askGroupList: true,
    askIgnoreAttachments: true,
    askTagList: true,
    askDomain: true,
  },
  ...(session.features.salesforce || allIntegrations
    ? {
        salesforce: {
          url: ({ state, salesforceSandbox }) =>
            [
              `https://${salesforceSandbox ? 'test' : 'login'}.salesforce.com/`,
              `services/oauth2/authorize`,
              `?response_type=code`,
              `&client_id=${identifiers.salesforce}`,
              `&redirect_uri=${callbackUrl}/salesforce`,
              `&state=${state}`,
            ].join(''),
          label: 'Salesforce',
          value: 'SALESFORCE',
          storageKey: integrationOAuthKey,
          askMasking: true,
          askIgnoreAttachments: true,
          askTagList: true,
        },
      }
    : {}),
  intercom: {
    url: ({ state }) =>
      [
        `https://app.intercom.com`,
        `/oauth`,
        `?redirect_uri=${callbackUrl}/intercom`,
        `&client_id=${identifiers.intercom}`,
        `&state=${state}`,
      ].join(''),
    label: 'Intercom',
    value: 'INTERCOM',
    storageKey: integrationOAuthKey,
    askMasking: true,
    askIgnoreAttachments: true,
    askTagList: true,
  },
  freshdesk: {
    label: 'Freshdesk',
    value: 'FRESHDESK',
    askToken: true,
    askFieldBlacklist: true,
    askGroupList: true,
    askIgnoreAttachments: true,
    askTagList: true,
    askUsername: true,
    askDomain: true,
  },
  kustomer: {
    label: 'Kustomer',
    value: 'KUSTOMER',
    askToken: true,
    askMasking: true,
    askFieldBlacklist: true,
    askIgnoreAttachments: true,
    askTagList: true,
    askDomain: true,
  },
  aircall: {
    url: ({ state }) =>
      [
        `https://dashboard-v2.aircall.io`,
        `/oauth/authorize`,
        `?response_type=code&scope=public_api`,
        `&redirect_uri=${callbackUrl}/aircall`,
        `&client_id=${identifiers.aircall}`,
        `&state=${state}`,
      ].join(''),
    label: 'Aircall',
    value: 'AIRCALL',
    storageKey: integrationOAuthKey,
    askIgnoreAttachments: true,
    askTagList: true,
    askMasking: true,
  },
  helpscout: {
    url: ({ state }) =>
      [
        `https://secure.helpscout.net`,
        `/authentication/authorizeClientApplication`,
        `?client_id=${identifiers.helpscout}`,
        `&state=${state}`,
      ].join(''),
    label: 'Help Scout',
    value: 'HELP_SCOUT',
    storageKey: integrationOAuthKey,
    askMasking: true,
    askFieldBlacklist: true,
    askMailboxList: true,
    askIgnoreAttachments: true,
    askTagList: true,
  },
  manual: {
    label: 'Custom Integration',
    value: 'MANUAL_IMPORT',
  },
  liveagent: {
    label: 'Live Agent',
    value: 'LIVE_AGENT',
    askToken: true,
    askMasking: true,
    askFieldBlacklist: true,
    askIgnoreAttachments: true,
    askTagList: true,
    askDomain: true,
  },
  livechat: {
    url: ({ state }) =>
      [
        `https://accounts.livechatinc.com`,
        `?response_type=code`,
        `&redirect_uri=${callbackUrl}/livechat`,
        `&client_id=${identifiers.livechat}`,
        `&state=${state}`,
      ].join(''),
    label: 'LiveChat',
    value: 'LIVE_CHAT',
    storageKey: integrationOAuthKey,
    askMasking: true,
    askGroupList: true,
    askIgnoreAttachments: true,
    askTagList: true,
  },
  wix: {
    label: 'WIX Answers',
    value: 'WIX',
    askToken: true,
    askMasking: true,
    askFieldBlacklist: true,
    askIgnoreAttachments: true,
    askTagList: true,
    askUsername: true,
    askDomain: true,
  },
  drift: {
    url: ({ state }) =>
      [
        `https://dev.drift.com`,
        `/authorize`,
        `?response_type=code`,
        `&redirect_uri=${callbackUrl}/drift`,
        `&client_id=${identifiers.drift}`,
        `&state=${state}`,
      ].join(''),
    label: 'Drift',
    value: 'DRIFT',
    storageKey: integrationOAuthKey,
    askMasking: true,
    askMailboxList: true,
    askIgnoreAttachments: true,
    askTagList: true,
  },
  'zendesk-chat': {
    url: ({ subdomain, state, clientId }) =>
      [
        `https://www.zopim.com`,
        `/oauth2/authorizations/new`,
        `?response_type=code`,
        `&redirect_uri=${encodeURIComponent(callbackUrl + '/')}zendesk-chat`,
        `&client_id=${clientId}`,
        `&scope=chat`,
        `&state=${state}`,
        subdomain ? `&subdomain=${subdomain}` : '',
      ].join(''),
    label: 'Zendesk Chat',
    value: 'ZENDESK_CHAT',
    redirectUri: `${callbackUrl}/zendesk-chat`,
    storageKey: integrationOAuthKey,
    askMasking: true,
    askOAuthApp: true,
    askGroupList: true,
    askIgnoreAttachments: true,
    askTagList: true,
    askDomain: true,
  },
  helpshift: {
    label: 'Helpshift',
    value: 'HELPSHIFT',
    askToken: true,
    askMasking: true,
    askCsatFilter: true,
    askIgnoreAttachments: true,
    askTagList: true,
    askDomain: true,
  },
  dixa: {
    label: 'Dixa',
    value: 'DIXA',
    askToken: true,
    askMasking: true,
    askIgnoreAttachments: true,
    askTagList: true,
    askDomain: true,
  },
  front: {
    url: ({ state }) =>
      [
        `https://app.frontapp.com/oauth/authorize`,
        '?response_type=code',
        `&redirect_uri=${callbackUrl}/front`,
        `&client_id=${identifiers.front}`,
        `&state=${state}`,
      ].join(''),
    storageKey: integrationOAuthKey,
    label: 'Front',
    value: 'FRONT',
    askMasking: true,
    askIgnoreAttachments: true,
    askTagList: true,
  },
  gorgias: {
    label: 'Gorgias',
    value: 'GORGIAS',
    url: ({ subdomain, state }) =>
      [
        `https://${subdomain}.gorgias.com`,
        `/oauth/authorize`,
        `?response_type=code`,
        `&redirect_uri=${callbackUrl}/gorgias`,
        `&client_id=${identifiers.gorgias}`,
        `&scope=openid email offline tickets:read tickets:write users:read tags:read events:read satisfaction_survey:read`,
        `&state=${state}`,
        `&preview=true`,
      ].join(''),
    storageKey: integrationOAuthKey,
    askMasking: true,
    askIgnoreAttachments: true,
    askTagList: true,
    askDomain: true,
  },
  talkdesk: {
    label: 'Talkdesk',
    value: 'TALKDESK',
    hideAdvancedOptions: true,
    askOAuthApp: true,
    askDomain: true,
  },
  freshchat: {
    label: 'Freshchat',
    value: 'FRESHCHAT',
    askToken: true,
    askDomain: true,
    askChannelList: true,
    hideIgnoreContent: true,
  },
  ...(session.features.genesys || allIntegrations
    ? {
        genesys: {
          label: 'Genesys',
          value: 'GENESYS',
          hideAdvancedOptions: true,
          status: 'beta',
          askOAuthApp: true,
        },
      }
    : {}),
  cordless: {
    url: ({ state }) =>
      `https://app.cordless.io/integrations/integration-confirm?redirect_url=${callbackUrl}/cordless?state=${state}`,
    storageKey: integrationOAuthKey,
    label: 'Cordless',
    value: 'CORDLESS_MANUAL_IMPORT',
  },
})

export const integrations = () => Object.values(integrationMap())

export const slackOAuthUrl = (redirectUri = config.slackCallbackUrl) => {
  if (!hasZendeskProxy && !session.features.zendeskProxyDev) {
    return `https://slack.com/oauth/authorize?scope=bot&client_id=${config.slackId}&redirect_uri=${redirectUri}`
  }

  const subdomain = location.hostname.split('.')[0]
  return `https://app.${config.domainRoot}/integrate-zd/${subdomain}/slack`
}
