export const TestLocales = {
  Bg: 'bg', // Bulgarian
  ZhCn: 'zh-cn', // Chinese (Simplified)
  ZhTw: 'zh-tw', // Chinese (Traditional)
  Cs: 'cs', // Czech
  Da: 'da', // Danish
  Nl: 'nl', // Dutch
  Fi: 'fi', // Finnish
  El: 'el', // Greek
  Hi: 'hi', // Hindi
  Hu: 'hu', // Hungarian
  Id: 'id', // Indonesian
  It: 'it', // Italian
  Ko: 'ko', // Korean
  No: 'no', // Norwegian
  Pl: 'pl', // Polish
  Ro: 'ro', // Romanian
  Ru: 'ru', // Russian
  Sv: 'sv', // Swedish
  Th: 'th', // Thai
  Tr: 'tr', // Turkish
  Vi: 'vi', // Vietnamese
} as const

export const DevLocales = {
  Latin: 'latin', // Latin
  EnPseudo: 'en-x-pseudo', // English (Pseudo)
} as const

export type TestLocale = (typeof TestLocales)[keyof typeof TestLocales]
export type DevLocale = (typeof DevLocales)[keyof typeof DevLocales]

export const ProdLocales = {
  EnGb: 'en-001', // English (United Kingdom)
  EnUs: 'en-US', // English (United States)
  PtBr: 'pt-br', // Portuguese (Brazil)
  Fr: 'fr', // French
  FrCa: 'fr-ca', // French Canadian
  De: 'de', // German
  Es: 'es', // Spanish
  Ja: 'ja', // Japanese
} as const

export const Locales = {
  ...ProdLocales,
  ...TestLocales,
  ...DevLocales,
} as const

export type Locale = (typeof Locales)[keyof typeof Locales]
